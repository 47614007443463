<template>
  <div class="v-stack h-stretch gap-5">
    <div class="pane-right gap-3">
      <div class="pane-left gap-3">
        <select v-model="filter" class="fixed">
          <option
            v-for="category in this.categories"
            :key="category"
            :value="category"
          >
            {{ category.charAt(0).toUpperCase() + category.slice(1) }}
          </option>
        </select>
        <input v-model="search" placeholder="type in to search name..." />
      </div>
      <button @click="$router.push('/xfiles/add')" class="add">New</button>
    </div>
    <Table
      :items="filteredItems"
      :defaultColumn="0"
      v-on:rowSelected="$router.push('/xfiles/edit/' + $event._id)"
      :css="'2fr 100px 100px 1fr 1fr'"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Category',
          sort: 'alphabet',
          path: ['category'],
        },
        {
          name: 'URL',
          sort: 'alphabet',
          path: ['url'],
          formatType: 'link',
        },
        {
          name: 'Login',
          sort: 'alphabet',
          path: ['login'],
          formatType: 'copy',
        },
        {
          name: 'Password',
          sort: 'alphabet',
          path: ['password'],
          formatType: 'copy',
        },
      ]"
    >
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import constants from "@/constants.js";

export default {
  data() {
    return {
      items: [],
      search: "",
      filter: "All",
      categories: (() => {
        const cat = ["All"].concat(
          constants.xfilesCategories.sort((a, b) => a.localeCompare(b, "cs"))
        );
        cat.splice(cat.indexOf("dalsi"), 1);
        cat.push("dalsi");
        return cat;
      })(),
    };
  },
  components: {
    Table,
  },
  computed: {
    filteredItems() {
      let items = this.items.slice();

      if (this.search.length > 0) {
        items = items.filter((item) => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      if (this.filter == "All") {
        return items;
      }

      return items.filter((user) => user.category == this.filter);
    },
  },
  methods: {
    ...mapActions(["getXFilesItems"]),
    refresh() {
      this.getXFilesItems()
        .then((items) => {
          this.items = items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
select.fixed {
  width: 200px;
}
</style>
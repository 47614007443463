var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-5"},[_c('div',{staticClass:"pane-right gap-3"},[_c('div',{staticClass:"pane-left gap-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"fixed",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filter=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((this.categories),function(category){return _c('option',{key:category,domProps:{"value":category}},[_vm._v(" "+_vm._s(category.charAt(0).toUpperCase() + category.slice(1))+" ")])}),0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"placeholder":"type in to search name..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/xfiles/add')}}},[_vm._v("New")])]),_c('Table',{attrs:{"items":_vm.filteredItems,"defaultColumn":0,"css":'2fr 100px 100px 1fr 1fr',"columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Category',
        sort: 'alphabet',
        path: ['category'],
      },
      {
        name: 'URL',
        sort: 'alphabet',
        path: ['url'],
        formatType: 'link',
      },
      {
        name: 'Login',
        sort: 'alphabet',
        path: ['login'],
        formatType: 'copy',
      },
      {
        name: 'Password',
        sort: 'alphabet',
        path: ['password'],
        formatType: 'copy',
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/xfiles/edit/' + $event._id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }